import React from "react";
import Column from "./Column";

const ColumnClassHelper = {
  3: "row row-cols-1 row-cols-lg-3",
  4: "row row-cols-1 row-cols-lg-2 row-cols-xxl-4",
};

const ColumnSection = (props) => {
  return (
    <section
      id={props.sectionId}
      className={`container-fluid py-4 px-4 px-lg-5 ${props?.classNames}`}
      style={{
        backgroundColor: props.backgroundColor ? props.backgroundColor : "#fff",
      }}
    >
      {props.title && (
        <div className="row mb-4">
          <div className="col ">
            <h1 className="lh-1 glifos-main-headline">{props.title}</h1>
          </div>
        </div>
      )}
      {props.description && (
        <div className="row mb-4">
          <div
            className="col"
            dangerouslySetInnerHTML={{ __html: props.description }}
          ></div>
        </div>
      )}
      <div className={ColumnClassHelper[props.columnCount]}>
        {props.columns.map((column, index) => (
          <Column key={index} {...column} />
        ))}
      </div>
    </section>
  );
};

export default ColumnSection;
