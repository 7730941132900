import React, { useEffect } from "react";

import {
  library_intro,
  library_features,
  library_modules,
} from "../../data/library";
import { news_section } from "../../data/news";
import { thesis_intro, thesis_features } from "../../data/thesis";
import { signer_headline, signer_features } from "../../data/signer";
import { release_banner } from "../../data/library_release10";

import BackgroundHero from "../../components/BackgroundHero/BackgroundHero";
import VerticalTabs from "../../components/VerticalTabs/VerticalTabs";
import SimpleHeadline from "../../components/SimpleHeadline/SimpleHeadline";
import ColumnSection from "../../components/ColumnSection/ColumnSection";
import HeadlineHero from "../../components/HeadlineHero/HeadlineHero";

const Dummy = () => {

  useEffect(() => {
    const script = document.createElement('script');
  
    script.src = "https://glifos.com/assets/js/chat.js";
    script.async = true;
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, []);


  return (
    <main>
      <div id="fb-root"></div>
      <div id="fb-customer-chat" class="fb-customerchat"></div>

      <BackgroundHero
        sectionId="library"
        backgroundClass={library_intro.backgroundClass}
        title={library_intro.title}
        description={library_intro.description}
        primaryButton={library_intro.primaryButton}
        secondaryButton={library_intro.secondaryButton}
      />
      <HeadlineHero
        title={release_banner.title}
        description={release_banner.description}
        image={release_banner.image}
        button={release_banner.button}
      />
      <ColumnSection
        sectionId="news"
        columnCount={news_section.columnCount}
        columns={news_section.columns}
        classNames="text-white glifos-bg-dark-gray"
      />
      <SimpleHeadline
        title={signer_headline.title}
        description={signer_headline.description}
        button={signer_headline.button}
      />
      <ColumnSection
        sectionId="about"
        title={library_features.title}
        backgroundColor={library_features.backgroundColor}
        columnCount={library_features.columnCount}
        columns={library_features.columns}
      />
      <VerticalTabs
        sectionId="modules"
        sectionTitle="Módulos"
        items={library_modules}
      />
      <BackgroundHero
        sectionId="thesis"
        backgroundClass={thesis_intro.backgroundClass}
        title={thesis_intro.title}
        description={thesis_intro.description}
        primaryButton={thesis_intro.primaryButton}
      />
      <ColumnSection
        sectionId="about-thesis"
        backgroundColor={thesis_features.backgroundColor}
        columnCount={thesis_features.columnCount}
        columns={thesis_features.columns}
      />
      <ColumnSection
        sectionId="about-signer"
        title={signer_features.title}
        description={signer_features.description}
        columnCount={signer_features.columnCount}
        columns={signer_features.columns}
      />
    </main>
  );
};

export default Dummy;
