import React from "react";

import styles from "./SimpleHeadline.module.css";

const SimpleHeadline = (props) => {
  return (
    <section className={`container-fluid ${styles.headline}`}>
      <div className="row row-cols-1 px-2 px-lg-5">
        <div className="d-lg-flex justify-content-center">
          <div className={`my-3 py-3 ${styles.headline_body}`}>
            <h5 className="glifos-headline">{props.title}</h5>
            <p dangerouslySetInnerHTML={{ __html: props.description }}></p>
          </div>
          <div className="d-lg-flex align-items-center ms-lg-5 mb-4 mb-lg-0">
            <a
              href={props.button.link}
              className="btn btn-dark btn-lg px-4 me-md-2"
            >
              {props.button.text}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SimpleHeadline;
