import React from "react";

import styles from "./MobileCard.module.css";

const MobileCard = (props) => {
  return (
    <div className={`card me-3 ${styles.mobile}`}>
      <div className={`p-3 glifos-module-name ${styles.header}`}>
        {props.title}
      </div>
      <div className="card-body">
        <div
          className={`mb-3 ${styles.headline}`}
          dangerouslySetInnerHTML={{ __html: props.header }}
        ></div>
        <div dangerouslySetInnerHTML={{ __html: props.body }}></div>
      </div>
    </div>
  );
};

export default MobileCard;
