import React from "react";
import DesktopCard from "./DesktopCard";
import MobileCard from "./MobileCard";
import NavButton from "./NavButton";

import styles from './VerticalTabs.module.css';

const VerticalTabs = (props) => {
  return (
    <section
      id={props.sectionId}
      className="container-fluid overflow-hidden py-4 py-lg-5 ps-4 ps-lg-5 pe-0"
      style={{ backgroundColor: "#53575f" }}
    >
      <div className="d-flex w-100">
        <div className={styles.title}>
          <h1 className="lh-1 mb-3 text-white glifos-section-title">
            {props.sectionTitle}
          </h1>
        </div>
      </div>
      <div className={`d-flex d-lg-none overflow-scroll ${styles.mobile_drag}`}>
        {props.items.map((item, index) => (
          <MobileCard
            key={index}
            title={item.title}
            header={item.header}
            body={item.body}
          />
        ))}
      </div>
      <div className="d-lg-flex d-none flex-row align-items-start">
        <div
          className={`nav flex-column nav-pills py-5 ${styles.nav}`}
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          {props.items.map((item, index) => (
            <NavButton key={index} id={index} text={item.title} />
          ))}
        </div>
        <div
          className={`tab-content bg-white py-5 ${styles.card}`}
          id="v-pills-tabContent"
        >
          {props.items.map((item, index) => (
            <DesktopCard
              key={index}
              id={index}
              header={item.header}
              body={item.body}
              screenshot={item.screenshot}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default VerticalTabs;
