export const cloud_intro = {
  title: "GLIFOS - cloud",
  backgroundClass: "bg-atardecer",
  description: `Ofrecemos la posibilidad de contratar nuestros sistemas en la nube como <i>Software as a Service</i>, al aplicar una infraestructura <i>multi tenant</i> optimizada nos permite ofrecer un menor precio en comparación de un licenciamiento convencional. Esto también facilita el proceso de actualización y mantenimiento de los sistemas ya que nos encargamos completamente del soporte de la infraestructura y respaldo de la información, la institución únicamente se preocupa por utilizar el sistema.`,
  primaryButton: {
    text: "Solicite una cotización",
    link: "#contactus",
  },
};

export const cloud_features = {
  backgroundColor: "#fbf9f8",
  columnCount: 3,
  columns: [
    {
      title: `Soporte agilizado`,
      body: `<p>Al estar encargados totalmente de la infraestructura y software, nos facilita proporcionar soporte a nuestros clientes al ahorrar tiempos de coordinación para accesos a los servidores.</p>`,
    },
    {
      title: `Simplicidad para la biblioteca`,
      body: `<p>No es necesario que la biblioteca tenga un departamento de IT dedicado para el soporte de la infraestructura ya que esta tarea es relegada a nosotros, los usuarios únicamente se preocupan en utilizar el sistema.</p>`,
    },
    {
      title: `Respaldo y restauración`,
      body: `<p>Nos encargamos de la elaboración de respaldos de forma periódica y resguárdalos en servicios en la nube con toda la seguridad respaldada por los proveedores de nube. Los procesos de restauración en caso de contingencia son más agiles al tener una infraestructura en la nube.</p>`,
    },
  ],
};