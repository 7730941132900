export const release_banner = {
    title: `<span class="glifos-font">GLIFOS - library</span> <span class="glifos-font-secondary">v10.1</span> ¡Ya disponible!`,
    description: `Nos complace compartir con nuestra comunidad de usuarios otro <i>release</i> de la nueva versión de <span class="glifos-font">GLIFOS - library</span>. En esta fase estamos incluyendo actualizaciones de módulos existentes e incorporando nuevos módulos que ofrecen funcionalidad adicional a la versión anterior; y siguiendo una estrategia de <i>continuous delivery</i> seguiremos actualizando todos los módulos del sistema.`,
    image: {
        src: "/assets/library/10.1/screenshots/circulation_station.png",
        href: "/library/releases/10#2023SEM1",
    },
    button: {
        title: "Ver más detalles",
        href: "/library/releases/10#2023SEM1",
    }
};

export const release_header = {
  title: `<span class="glifos-font">GLIFOS - library</span> <span class="glifos-font-secondary">10</span>`,
  description: `<p class="lead"><time class="fw-bold">Marzo 10, 2022</time> · Presentamos la versión 10 de nuestro sistema <span class="glifos-font">GLIFOS - library</span>, esta es una <b>actualización mayor</b>.</p><p class="lead">Continuamente recibimos sugerencias e ideas valiosas para mejorar nuestros sistemas.  Teniendo siempre en mente la visión de apoyar el futuro de las bibliotecas en una era tecnológica, en esta nueva versión hemos incorporado buena parte de las ideas y sugerencias que hemos recibido de nuestros clientes.</p>`,
};

export const release_plan = {
    header: `<h3 class="glifos-main-headline">Planificación</h3><p class="lead">Los <i>releases</i> tienen un enfoque de <span class="fst-italic">continuous delivery</span> que nos permitirá agilizar las actualizaciones y minimizar el tiempo de estabilización, al dividir el sistema en diferentes fases. Tomar en cuenta que las fechas detalladas a continuación son estimaciones sujetas a cambios.</p>`,
    releases: [
        {
            id: "2022SEM1",
            title: "Primer semestre 2022",
            features: [
                {
                    id: "opac",
                    title: "1. Nueva interface para el catálogo en línea (OPAC)",
                    released: true,
                    category: "Actualización de módulo",
                    description: `<p>Renovamos el diseño de nuestro OPAC, siguiendo los lineamientos de un diseño <i>web responsive</i> creamos una interfaz simple y moderna que mantiene la sobriedad de una biblioteca, la cual puede ser consultada desde cualquier dispositivo, ya que se ajusta según la resolución de la pantalla en la que se esté visualizando.</p>`,
                    details: `<img src="/assets/library/10.0/screenshots/opac_main.png" class="img-fluid mb-2" alt="opac_main" />
                    <p>El OPAC se mantiene personalizable, permitiendo cambiar el logo y paleta de colores, acorde a cada institución. La portada permite agregar <b>N</b> cantidad de secciones con información que la biblioteca desee, permitiendo al catálogo convertirse en la cara web de la biblioteca, manteniendo al tanto a sus usuarios con la información más relevante.</p>
                    <p>La portada muestra las novedades, que corresponden a los últimos libros que han sido catalogados, si la institución cuenta con el módulo de nuevas adquisiciones, se puede tener aún más personalización en la sección de novedades.</p>
                    <img src="/assets/library/10.0/screenshots/opac_cover.png" class="img-fluid" alt="opac_cover" />
                    <p>La búsqueda ha sido simplificada a una sola caja de texto, con opciones para filtrar por: Palabra clave, autor, título o clasificación.</p>
                    <img src="/assets/library/10.0/screenshots/opac_search.gif" class="img-fluid" alt="opac_search" />
                    <p>La búsqueda avanzada se ha renovado, se mantiene la búsqueda booleana y se adicionaron filtros para una parametrización más completa.</p>
                    <img src="/assets/library/10.0/screenshots/opac_advanced_search.png" class="img-fluid" alt="opac_advanced_search" />
                    <p>Los resultados de búsqueda ahora cuentan con filtros laterales, permitiendo a los usuarios refinar los resultados desde una misma interfaz. En cada resultado se especifica si el material se encuentra disponible para préstamo físico o si es un material digital, permitiendo a los usuarios saber si pueden prestar el material sin necesidad de entrar a cada registro en específico.</p>
                    <p>Cada resultado presenta opción para compartir el enlace del material u obtener una cita bibliográfica en normas APA del material.</p>
                    <p><b>NOTA:</b> Las citas o referencias bibliográficas son autogeneradas con la información disponible en cada registro, pueden estar incompletas o contener datos erróneos.</p>
                    <img src="/assets/library/10.0/screenshots/opac_results.png" class="img-fluid" alt="opac_results" />
                    <p>La vista de un registro individual ha sido renovada, permitiendo realizar búsquedas simples desde esta pantalla, al igual que los resultados de búsqueda permite compartir o citar el registro especifico.</p>
                    <p>Cuenta con una sección lateral que resalta la disponibilidad del material, si hay copias disponibles o acceso en línea, permitiendo a los usuarios saber si pueden acceder al material sin tener que hacer <i>scroll</i> a la sección de inventario.</p>
                    <p>La sección de inventario se modificó en un formato de tarjetas, con opción a filtrar por biblioteca, facilitando a los usuarios consultar las copias que se encuentren en la sede de su interés.</p>
                    <img src="/assets/library/10.0/screenshots/opac_record.png" class="img-fluid" alt="opac_record" />`
                },
                {
                    id: "admin",
                    title: "2: Nuevo módulo de administración (Previamente editor de tablas)",
                    released: true,
                    category: "Actualización de módulo",
                    description: `<p>El módulo de administración ha sido renovado con un nuevo diseño <i>web responsive</i>, hemos creado esta nueva interface administrativa que puede ser consultada desde cualquier dispositivo.</p>`,
                    details: `<img src="/assets/library/10.0/screenshots/admin_main.png" class="img-fluid mb-2" alt="admin_main" />
                    <p>Cuenta con un <i>login</i> centralizado, para las cuentas del staff de biblioteca. Ya no será necesario ingresar las credenciales en cada módulo del sistema, desde esta nueva interfaz administrativa, se tendrá acceso a todos los módulos que requieren autenticación, el menú lateral mostrará el acceso a todos los módulos en los que el usuario tiene permisos. Actualmente solo se encuentra disponible el nuevo módulo de administración, pero la nueva versión de los módulos administrativos se irá integrando en esta interface en futuras publicaciones.</p>
                    <img src="/assets/library/10.0/screenshots/admin_login.png" class="img-fluid mb-2" alt="admin_login" />
                    <p>Al igual que el editor de tablas anterior, el módulo de administración permite ver, crear, actualizar y eliminar datos de los registros maestros como: Instituciones, departamentos, materiales, etc.</p>
                    <p>También es posible gestionar cuentas de staff y usuarios de la biblioteca, y realizar configuraciones de tipos de usuarios y circulación.</p>
                    <img src="/assets/library/10.0/screenshots/admin_index.png" class="img-fluid mb-2" alt="admin_index" />`
                },
                {
                    id: "chatbot",
                    title: "3: Asistente virtual de biblioteca para chat (Chatbot)",
                    released: true,
                    category: "Nuevo módulo",
                    description: `<p>Presentamos nuestro nuevo módulo, el asistente virtual de biblioteca, es un chatbot que actuará como primera línea de atención a los usuarios. Para su implementación se utilizó como motor el servicio en la nube de Google: <b><i>Dialogflow CX</i></b>, al aprovechar su increíble capacidad para el reconocimiento del lenguaje natural, el chatbot ofrece una experiencia natural y fluida para los usuarios durante la conversación.</p>`,
                    details: `<p>El servicio del chatbot, esta integrado con Facebook Messenger, lo cual permite integrar su plugin de chat en cualquier página web ¡Incluyendo nuestro nuevo OPAC!</p>
                    <p>El plugin de chat permite acceder con una cuenta de Facebook o como invitado, para que cualquier usuario pueda realizar consultas al asistente virtual sin problemas.</p>
                    <div class="text-center"><img src="/assets/library/10.0/screenshots/chatbot_plugin.png" class="img-fluid mb-2" alt="chatbot_plugin" /></div>
                    <p>El asistente virtual tiene la capacidad de contestar consultas que son comúnmente realizadas a la biblioteca de forma rápida, mejorando los tiempos de atención a los usuarios.</p>
                    <div class="text-center"><img src="/assets/library/10.0/screenshots/chatbot_faq.png" class="img-fluid mb-2" alt="chatbot_faq" /></div>
                    <p>El asistente virtual también tiene la capacidad de acceder a la base de datos de <span class="glifos-font">GLIFOS - library</span>, permitiendo a los usuarios realizar gestiones como: Consultar materiales, solicitar solvencias o recibir recordatorios de préstamos o multas pendientes (siempre y cuando el usuario se haya autenticado previamente).</p>
                    <div class="text-center"><img src="/assets/library/10.0/screenshots/chatbot_feature.png" class="img-fluid mb-2" alt="chatbot_feature" /></div>`
                },
            ]
        },
        {
            id: "2022SEM2",
            title: "Segundo semestre 2022",
            features: [
                {
                    id: "unitedcatalog",
                    title: "1. Consorcio de bibliotecas",
                    released: true,
                    category: "Nuevo módulo",
                    description: `<p>Utilizando las capacidades de búsqueda y diseño de nuestro renovado OPAC, se creó un repositorio que es capaz de centralizar distintas bases de datos en un solo catálogo unificado, permitiendo a los usuarios consultar en un solo lugar todos los materiales disponibles en todas las bases de datos integradas.</p>`,
                    details: `<p>Es capaz de mantener actualizado en tiempo real la disponibilidad de los materiales presentes en el repositorio y cuenta con intervalos de sincronización para obtener los registros agregados en cada base de datos.</p>
                    <p>El repositorio es totalmente compatible con <span class="glifos-font">GLIFOS - library</span> en su versión 10, sin embargo, es agnóstico al sistema de gestión de biblioteca de cada catálogo individual, basta con que se utilice el estándar internacional MARC 21 para poder integrarse.</p>
                    <p><b>NOTA:</b> Es posible que se requiera implementar un adaptador dependiendo del sistema del cual se origine la información.</p>
                    <br/>
                    <h4>Caso de éxito: Wichiteca</h4>
                    <div class="text-center"><img src="/assets/library/10.0/screenshots/opac_wichiteca.png" class="img-fluid mb-2" alt="opac_wichiteca" /></div>
                    <p class="text-center"><a href="https://wichiteca.com" target="_blank">Visitar Wichiteca</a></p>
                    <p><i>Wichiteca</i> es el catálogo unificado de diversas bibliotecas académicas de Guatemala. Nace de un esfuerzo conjunto entre el Ing. Celso Cerezo Bregni y <span class="glifos-font">GLIFOS</span> por integrar el acceso al conocimiento tanto para estudiantes e investigadores como la sociedad en general.</p>`
                    
                },
            ]
        },
        {
            id: "2023SEM1",
            title: "Primer semestre 2023",
            features: [
                {
                    id: "sso",
                    title: "1. Nueva autenticación <i>Single Sign On</i> (SSO)",
                    released: true,
                    category: "Actualización de módulo",
                    description: `<p>El módulo de autenticación ha sido actualizado a un SSO, el cual permite integrarse fácilmente a otros medios de autenticación como correos institucionales para minimizar la cantidad de contraseñas que deben manejar los usuarios dentro de una institución. </p>`,
                    details: `<div class="text-center"><img src="/assets/library/10.1/screenshots/login_sso.png" class="img-fluid mb-2" alt="login_sso" /></div>
                    <p>La autenticación se ha centralizado para las cuentas tanto para cuentas de usuarios de la biblioteca como administrativos. Es compatible por defecto con Google, Office 365, Active Directory o cualquier otro medio con LDAP. El módulo puede ser extendido para integrarse con servicios a la medida de la institución si no se encuentra en los medios compatibles por defecto.</p>
                    <p>La pantalla de autenticación puede ser personalizada con el logo y fondo de la institución.</p>`,
                },
                {
                    id: "thesis",
                    title: `2. Actualización de módulo <span class="glifos-font">GLIFOS - thesis self deposit</span>`,
                    released: true,
                    category: "Actualización de módulo",
                    description: `<p>El módulo de auto depósito de tesis ha sido renovado con un nuevo diseño acoplado a la versión 10.1 de <span class="glifos-font">GLIFOS - library</span>, hemos mejorado las funcionalidades clave para optimizar el proceso de entrega y revisión de los documentos de graduación.</p>`,
                    details: `<div class="text-center"><img src="/assets/library/10.1/screenshots/thesis_user_panel.png" class="img-fluid mb-2" alt="thesis_user_panel" /></div>
                    <p>Se ha integrado con la nueva autenticación SSO lo que facilita el proceso para los usuarios al poder ingresar al módulo utilizando su cuenta institucional, reduciendo los tiempos de inicio del proceso y la necesidad de manejar nuevas contraseñas.</p>
                    <p>El proceso de entrega de documentos se ha divido visualmente en varios pasos para facilitar al usuario la revisión de los datos consignados en cada etapa de la solicitud. Es posible requerir al usuario constancias de que se encuentra en la etapa para poder presentar su documento de graduación, esto optimiza el proceso ya que se evita una autorización de la cuenta del estudiante por parte de la institución.</p>
                    <div class="text-center"><img src="/assets/library/10.1/screenshots/thesis_user_wizard_2.png" class="img-fluid mb-2" alt="thesis_user_wizard_2" /></div>
                    <p>Permite a la institución definir una lista de requerimientos para la entrega de un documento, adicional a la carga del documento en formato PDF, los usuarios deben marcar la evidencia de cumplimiento de estos requerimientos en el documento a presentar. Con nuestro nuevo visor de PDF en el formulario de entrega este proceso se vuelve fácil e intuitivo para el usuario.</p>
                    <div class="text-center mb-3"><img src="/assets/library/10.1/screenshots/thesis_user_wizard_3.gif" class="img-fluid mb-2" alt="thesis_user_wizard_3" /></div>
                    <p>La interfaz del revisor se ha mejorado, el panel de documentos se ha divido en varias secciones según el estado en el que se encuentra cada documento, al igual que una bandeja de entrada de correo, mostrando siempre lo más reciente al inicio y proporcionando filtros de búsqueda para localizar documentos específicos. </p>
                    <div class="text-center"><img src="/assets/library/10.1/screenshots/thesis_document_panel.png" class="img-fluid mb-2" alt="thesis_document_panel" /></div>
                    <p>El proceso de revisión también se ha divido visualmente en varios pasos, facilitando el proceso al revisor ya que puede rechazar la solicitud en el paso en que se identifique que no cumple con los requerimientos. Al momento de la revisión de los requerimientos el revisor es presentado con nuestro nuevo visor de PDF, permitiendo en una sola vista visualizar la lista de requerimientos, el PDF con las evidencias y la lista de cotejo para marcar si el documento cumple con cada requerimiento. </p>
                    <div class="text-center"><img src="/assets/library/10.1/screenshots/thesis_admin_wizard_3.gif" class="img-fluid mb-2" alt="thesis_admin_wizard_3" /></div>`,
                },
            ]
        },
        {
            id: "2023SEM2",
            title: "Segundo semestre 2023",
            features: [
                {
                    id: "admin10_1",
                    title: "1. Actualización de módulo de administración",
                    released: true,
                    category: "Actualización de módulo",
                    description: `<p>El módulo de administración ha sido mejorado en la versión 10.1, ajustando aspectos visuales y funcionalidad para mejorar la usabilidad y experiencia de usuario.</p>
                    <p>Se ha mejorado la seguridad y flexibilidad del sistema al introducir la posibilidad de creación de roles y permisos específicos para cada acción, aprovechando las características de la nueva autenticación SSO al máximo permite a ser granular en las acciones que tiene permitido realizar cada usuario.</p>`,
                    details: `<p>El menú lateral ha sido actualizado para aprovechar los roles, solo mostrando acceso a los módulos que el usuario autenticado tiene permiso de operar. El menú principal del módulo ha sido renovado visualmente a tarjetas con descripciones para cada una de las configuraciones disponibles.</p>
                    <div class="text-center"><img src="/assets/library/10.1/screenshots/admin_main_menu.png" class="img-fluid mb-2" alt="admin_main_menu" /></div>
                    <p>La interfaz para visualización, creación, actualización o eliminación de cada configuración se ha actualizado, se agregaron filtros de búsqueda para facilitar la localización de un elemento en específico.</p>
                    <div class="text-center"><img src="/assets/library/10.1/screenshots/admin_crud.png" class="img-fluid mb-2" alt="admin_crud" /></div>`,
                },
                {
                    id: "technicalprocess",
                    title: "2. Módulo de procesos técnicos",
                    released: true,
                    category: "Nuevo módulo",
                    description: `<p>El nuevo módulo de procesos técnicos permite a la biblioteca hacer traslados de materiales entre diferentes áreas para procesos internos como la catalogación, empastado o etiquetado. Mientras actualiza el estado del material en cada operación.</p>`,
                    details: `<p>Permite configurar libremente las áreas dentro de la institución a las que se puede trasladar un material, asignar operadores a cada área, los tipos de transacción y el estado al que cambia con cada movimiento.</p>
                    <div class="text-center"><img src="/assets/library/10.1/screenshots/technicalprocess_area_crud.png" class="img-fluid mb-2" alt="technicalprocess_area_crud" /></div>
                    <p>La interfaz principal del módulo permite en un solo lugar realizar todas las operaciones, entre ellas la consulta de historial, envío, recepción y finalización de proceso. A su vez, muestra todos los materiales que el usuario autenticado tiene asignados según su área.</p>
                    <div class="text-center"><img src="/assets/library/10.1/screenshots/technicalprocess_station.png" class="img-fluid mb-2" alt="technicalprocess_station" /></div>
                    <p>Permite realizar envíos individuales o en lote, simplemente escaneando o digitando el código de barras de cada material. Por cada operación realizada se envían notificaciones por correo a los operadores involucrados, facilitando el acceso a la recepción de los materiales en el traslado.</p>
                    <div class="text-center"><img src="/assets/library/10.1/screenshots/technicalprocess_send.png" class="img-fluid mb-2" alt="technicalprocess_send" /></div>
                    <p>Despliega un resumen en todo momento de los materiales asignaos al operador y su área, manteniendo la información relevante para el usuario a la vista para agilizar cada transacción.</p>
                    <div class="text-center"><img src="/assets/library/10.1/screenshots/technicalprocess_summary.png" class="img-fluid mb-2" alt="technicalprocess_summary" /></div>
                    <p>El módulo incluye cinco reportes adicionales para la visualización de todas las operaciones realizadas en este, según los criterios de la institución.</p>`,
                },
                {
                    id: "circulation",
                    title: "3. Actualización de módulo de circulación",
                    released: true,
                    category: "Actualización de módulo",
                    description: `<p>Renovamos el diseño del módulo de circulación con una interfaz simple y moderna que mantiene la sobriedad de una biblioteca, también se mejoraron funcionalidades y la retroalimentación visual para que sea aún más intuitivo el uso del módulo.</p>`,
                    details: `<div class="text-center"><img src="/assets/library/10.1/screenshots/circulation_station.png" class="img-fluid mb-2" alt="circulation_station" /></div>
                    <p>El módulo se ha integrado a la nueva interfaz administrativa y SSO, permitiendo su acceso solo a aquellos operadores con los permisos necesarios, todas las operaciones disponibles en la versión 8 se han mantenido, con un nuevo aspecto visual para cada elemento.</p>
                    <p>Cada cuenta de usuario tiene la posibilidad de manejar <b>N</b> cantidad de identificadores para el usuario como el carné, número de identificación personal o pasaporte, de acuerdo a las necesidades de la biblioteca. Se agregó un campo de búsqueda dedicado, el cual permite buscar por cualquiera de los identificadores.</p>
                    <div class="text-center mb-3"><img src="/assets/library/10.1/screenshots/circulation_identifier_search.gif" class="img-fluid mb-2" alt="circulation_identifier_search" /></div>
                    <p>También es posible realizar búsqueda por nombre, en la ventana emergente se despliega el listado de usuarios ordenado alfabéticamente con opción de filtrar por coincidencias parciales de nombre o identificador.</p>
                    <div class="text-center mb-3"><img src="/assets/library/10.1/screenshots/circulation_name_search.gif" class="img-fluid mb-2" alt="circulation_name_search" /></div>
                    <p>Los menús de opciones se han renovado permitiendo realizar búsquedas de una forma más fácil, basta únicamente con empezar a escribir y desplegará las coincidencias según el texto ingresado.</p>
                    <div class="text-center mb-3"><img src="/assets/library/10.1/screenshots/circulation_dropdown_search.gif" class="img-fluid mb-2" alt="circulation_dropdown_search" /></div>
                    <p>Se han extendido los campos para información de contacto, permitiendo por defecto ingresar dos números de teléfono y dos correos con la posibilidad de extenderlos según los requerimientos de la biblioteca.</p>
                    <div class="text-center mb-3"><img src="/assets/library/10.1/screenshots/ciruclation_station_contacts.png" class="img-fluid mb-2" alt="ciruclation_station_contacts" /></div>
                    <p>Al crear o actualizar cuentas de usuario los botones despliegan retroalimentación visual para que el operador pueda identificar que la opción ha sido habilitada.</p>
                    <div class="text-center mb-3"><img src="/assets/library/10.1/screenshots/circulation_update_user.gif" class="img-fluid mb-2" alt="circulation_update_user" /></div>
                    <p>Se ha mejorado la opción para cambio de fotografía, permitiendo la carga del archivo desde el módulo directamente o bien colocando el URL de la imagen si se encuentra almacenada en otro servicio de tipo web.</p>
                    <div class="text-center mb-3"><img src="/assets/library/10.1/screenshots/circulation_update_photo.gif" class="img-fluid mb-2" alt="circulation_update_photo" /></div>
                    <p>La opción de creación de memos ha sido renovada, permitiendo crear más de un memo para cada usuario, guardando automáticamente la fecha y el nombre del operadore que lo creó, con opción de agregar texto con formato. Los memos pueden ser eliminados por un operador con los permisos de administración del módulo.</p>
                    <div class="text-center"><img src="/assets/library/10.1/screenshots/circulation_add_memo.gif" class="img-fluid mb-2" alt="circulation_add_memo" /></div>`,
                },
                {
                    id: "calendar",
                    title: "4. Actualización módulo de calendario",
                    released: true,
                    category: "Actualización de módulo",
                    description: `<p>El módulo de calendario ha sido renovado con un nuevo diseño, siguiendo la misma línea de la versión 10 con una interfaz simple y moderna que hace más intuitivo su uso para los operadores.</p>`,
                    details: `<p>El módulo se ha integrado a la nueva interfaz administrativa y SSO, permitiendo su acceso solo a aquellos operadores con los permisos necesarios, todas las operaciones disponibles en la versión 8 se han mantenido, con un nuevo aspecto visual para cada elemento.</p>
                    <div class="text-center"><img src="/assets/library/10.1/screenshots/calendar_year.png" class="img-fluid mb-2" alt="calendar_year" /></div>
                    `,
                },
                {
                    id: "user_dashboard",
                    title: "Módulo Dashboard de usuarios (Previamente Estatus de usuario)",
                    released: true,
                    category: "Actualización de módulo",
                    description: `<p>Este nuevo módulo sustituye al Estatus de usuario disponible en la versión 8, renovando con un nuevo diseño moderno e intuitivo que sigue los lineamientos de un diseño web responsive, permitiendo ser consultado desde cualquier dispositivo ya que se ajusta según la resolución de pantalla en la que se esté visualizando. </p>`,
                    details: `<div class="text-center"><img src="/assets/library/10.1/screenshots/user_dashboard.png" class="img-fluid mb-2" alt="user_dashboard" /></div>
                    <p>El módulo permite en una sola interfaz ver su estado actual en la biblioteca y realizar todas las operaciones disponibles en línea, al estar integrado con el SSO los usuarios pueden realizar sus gestiones de forma más ágil al no requerir un pin especifico de biblioteca si ya cuentan con un usuario institucional.</p>
                    <p>Los usuarios podrán visualizar sus datos almacenados en el sistema, actualizar su correo de contacto, visualizar los préstamos, reservas y multas activas.</p>
                    <div class="text-center mb-3"><img src="/assets/library/10.1/screenshots/user_dashboard_user_info.png" class="img-fluid mb-2" alt="user_dashboard_user_info" /></div>
                    <p>Si las configuraciones de la biblioteca lo permiten, los usuarios podrán realizar renovaciones en línea desde esta interfaz.</p>
                    <div class="text-center mb-3"><img src="/assets/library/10.1/screenshots/user_dashboard_loans.png" class="img-fluid mb-2" alt="user_dashboard_loans" /></div>
                    <p>La reserva desde el OPAC se ha integrado a este módulo, permitiendo al usuario realizar la gestión de forma ágil si ya cuenta con una sesión activa en su navegador.</p>
                    <div class="text-center"><img src="/assets/library/10.1/screenshots/user_item_booking.png" class="img-fluid mb-2" alt="user_item_booking" /></div>
                    <p>Todo el historial del usuario en la biblioteca siempre estará a su disposición en la sección de historial.</p>
                    <div class="text-center mb-3"><img src="/assets/library/10.1/screenshots/user_dashboard_history.png" class="img-fluid mb-2" alt="user_dashboard_history" /></div>
                    <p>Según los módulos disponibles para la institución, los usuarios tendrán opciones adicionales presentes en este módulo, como el auto depósito de tesis, solvencias o pago de multas.</p>`,
                },
            ]
        },
        {
            id: "2024SEM1",
            title: "Primer semestre 2024",
            features: [
                {
                    id: "analytics",
                    title: "1. Data Analytics (Análisis de estadísticas de uso)",
                    released: false,
                    category: "Nuevo módulo",
                    description: `<p>Estadísticas que van más allá de los reportes mensuales de circulación, catalogación y nuevas adquisiciones, permiten el monitoreo y toma de decisiones sobre aspectos clave, cada vez más relevantes en el quehacer de las bibliotecas.</p>`,
                },
                {
                    id: "authorities",
                    title: "2. Control de autoridades",
                    released: false,
                    category: "Nuevo módulo",
                    description: `<p>Nuevo módulo de Control de Autoridades de conformidad con <i>MARC 21 Format for Authority Data Update No. 29</i> (Nov/2019).</p>`,
                },
                {
                    id: "cataloging1",
                    title: "3. Integración de catalogación con control de autoridades",
                    released: false,
                    category: "Actualización de módulo",
                    description: `<p>Actualización del módulo de catalogación para ser compatible con el módulo de autoridades.</p>`,
                },
                {
                    id: "capturex",
                    title: `4. Smart <span class="glifos-font">Capture X</span>`,
                    released: false,
                    category: "Actualización de módulo",
                    description: `<p>Agiliza aún más la labor de catalogación, permitiendo reducir la carga de procesos técnicos existente.</p>
                    <p>Ingresa de forma autónoma a los catálogos de biblioteca designados por el/la catalogador(a), consulta registros vía ISBN, traduce de inglés a español en campos específicos, y genera una cita preliminar para revisión, ajustes y aprobación del catalogador. </p>`,
                },
                {
                    id: "rating",
                    title: "5. Rating y comentarios",
                    released: false,
                    category: "Nuevo módulo",
                    description: `<p>Permite a los usuarios calificar los libros de la biblioteca o dejar comentarios con sus opiniones y experiencias relacionadas a dicho material. Esta opción estará disponible únicamente para usuarios autenticados dentro del OPAC.</p>`,
                },
            ]
        },
        {
            id: "2024SEM2",
            title: "Segundo semestre 2024",
            features: [
                {
                    id: "cataloging2",
                    title: "1. Catalogación",
                    released: false,
                    category: "Actualización de módulo",
                    description: `<p>Nueva versión del módulo de catalogación, con un nuevo diseño <i>web responsive</i>, integrado a la interface administrativa e integrado totalmente al nuevo control de autoridades, con una nueva forma optimizada de crear copias de materiales, permitiendo manejar grandes cantidades de copias en un solo registro sin comprometer el tiempo de carga del registro.</p>`,
                },
                {
                    id: "globalUpdates",
                    title: "2. Actualización global (Previamente cambios globales)",
                    released: false,
                    category: "Actualización de módulo",
                    description: `<p>Nueva versión del módulo de cambios globales, con un nuevo diseño <i>web responsive</i>, integrado a la interface administrativa y al control de autoridades.</p>`,
                },
                {
                    id: "reports",
                    title: "3. Reportes",
                    released: false,
                    category: "Actualización de módulo",
                    description: `<p>Nueva versión del módulo de reportes, con un nuevo diseño <i>web responsive</i>, integrado a la interface administrativa, capacidad de generar reportes en Excel y en segundo plano. Las estadísticas de los reportes estarán integradas el módulo de Data Analytics.</p>`,
                },
                {
                    id: "z3950",
                    title: `4. z39.50`,
                    released: false,
                    category: "Nuevo módulo",
                    description: `<p>Módulo <i>add-on</i> de z39.50 para la importación de registros en <span class="glifos-font">GLIFOS</span> facilitando y optimizando el tiempo de ingreso de registros bibliográficos por medio de la comunicación con un gran número de bibliotecas que utilizan el protocolo a nivel mundial.</p>`,
                },
                {
                    id: "inventory",
                    title: "5. Inventario",
                    released: false,
                    category: "Actualización de módulo",
                    description: `<p>Nueva versión del módulo de inventario físico, con un nuevo diseño <i>web responsive</i>, integrado a la interface administrativa. Al tener un nuevo proceso de carga y comparación optimizada, facilita a los bibliotecarios contrastar el inventario ingresado al sistema. Las estadísticas estarán integradas al módulo de Data Analytics. </p>`,
                },
            ]
        },
    ]
}
