import React from "react";

const ContactUs = () => {
  return (
    <section
      id="contactus"
      className="container-fluid py-4 py-lg-5 px-4 px-lg-5"
      style={{ backgroundColor: "#3F4248" }}
    >
      <div className="row mb-4">
        <div className="col ">
          <h1 className="lh-1 glifos-main-headline text-white">Contáctenos</h1>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-lg-2">
        <div className="col mb-4">
          <div className="card h-100 ">
            <div className="card-img-top p-5 glifos-bg-yellow">
              <div className="d-flex flex-column justify-content-start">
                <span style={{ fontSize: "3rem" }}>
                  <i className="fas fa-envelope"></i>
                </span>
                <span className="glifos-subheadline">Correos</span>
              </div>
            </div>
            <div className="card-body">
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <a href="mailto:info@glifos.com" className="text-link">
                    info@glifos.com
                  </a>
                </li>
                <li className="list-group-item">
                  <a href="mailto:sales@glifos.com" className="text-link">
                    sales@glifos.com
                  </a>
                </li>
                <li className="list-group-item">
                  <a href="mailto:tickets@glifos.com" className="text-link">
                    tickets@glifos.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col mb-4">
          <div className="card h-100">
            <div className="card-img-top p-5 glifos-bg-yellow">
              <div className="d-flex flex-column justify-content-start">
                <span style={{ fontSize: "3rem" }}>
                  <i className="fas fa-phone-alt"></i>
                </span>
                <span className="glifos-subheadline">Teléfonos</span>
              </div>
            </div>
            <div className="card-body">
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <a href="tel:+50266345515" className="text-link">
                    +502 6634 5515
                  </a>
                </li>
                <li className="list-group-item">
                  <a href="tel:+50266345516" className="text-link">
                    +502 6634 5516
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
