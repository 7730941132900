import React, { useState } from "react";

const detailsHelper = {
  true: `Ocultar detalle <i class="fas fa-angle-up"></i>`,
  false: `Ver detalle <i class="fas fa-angle-down"></i>`,
};

const ReleaseSection = (props) => {
  const [toggleDetails, setToggleDetails] = useState(false);

  return (
    <div className="mb-4">
      <div className="border-bottom mb-4">
        <h5>
          <span dangerouslySetInnerHTML={{__html: props.title}}></span>&nbsp;
          {props.released === true && (
            <span class="ms-2 badge bg-success bg-gradient mt-2 mt-lg-0">
              <i class="fas fa-certificate"></i> Released!
            </span>
          )}
        </h5>
      </div>
      <div>
        <figcaption class="blockquote-footer">
          Categoría: {props.category}
        </figcaption>
        <div dangerouslySetInnerHTML={{ __html: props.description }}></div>
        {props.details && (
          <div className={`mb-3 ${toggleDetails ? 'border-bottom' : ''}`}>
            <a
              class="text-link"
              data-bs-toggle="collapse"
              href={`#feature-${props.id}`}
              role="button"
              aria-expanded="false"
              aria-controls="opacDetail"
              dangerouslySetInnerHTML={{ __html: detailsHelper[toggleDetails] }}
              onClick={() => setToggleDetails(!toggleDetails)}
            ></a>
          </div>
        )}
      </div>
      {props.details && (
        <div
          class="collapse"
          id={`feature-${props.id}`}
          dangerouslySetInnerHTML={{ __html: props.details }}
        ></div>
      )}
    </div>
  );
};

export default ReleaseSection;
