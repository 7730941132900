export const ojs_headline = {
    title: `GLIFOS for <i>Open Journal Systems</i> (OJS)`,
    description: `¿Necesita servicios de instalación, configuración o actualización para <i>Open Journal Systems</i> (OJS)? Nuestra <i>suite</i> de servicios GLIFOS for OJS es lo que necesita.`,
    button: {
        text: "Conozca más",
        link: "#glifosforojs",
    },
};

export const ojs_intro = {
    title: `GLIFOS for <i>Open Journal Systems</i> (OJS)`,
    backgroundClass: "bg-jupiter-center",
    description: `Ponemos a disposición nuestros servicios de instalación, configuración, actualización para <i>Open Journal Systems</i> (OJS), contamos con una suite de servicios para integración entre otros OJS o bien con los sistemas de la marca GLIFOS, así como módulos adicionales para expandir la funcionalidad de OJS. `,
    primaryButton: {
        text: "Solicite una cotización",
        link: "#contactus",
    },
};

export const ojs_features = {
    backgroundColor: "#fbf9f8",
    columnCount: 4,
    columns: [
        {
            title: `Instalación`,
            body: `<p>Servicios de instalación, configuración y personalización de instancias OJS en infraestructura ,i>on premise</i> o en la nube, siguiendo las mejores prácticas de seguridad para un software open source.</p>`,
        },
        {
            title: `Actualización y migración`,
            body: `<p>No es necesario que la biblioteca tenga un departamento de IT dedicado para el soporte de la infraestructura ya que esta tarea es relegada a nosotros, los usuarios únicamente se preocupan en utilizar el sistema.</p>`,
        },
        {
            title: `Capacitación`,
            body: `<p>Nos encargamos de la elaboración de respaldos de forma periódica y resguárdalos en servicios en la nube con toda la seguridad respaldada por los proveedores de nube. Los procesos de restauración en caso de contingencia son más agiles al tener una infraestructura en la nube.</p>`,
        },
        {
            title: `Adaptación`,
            body: `<p>Capacidad de expandir las funcionalidades de OJS, integraciones con cosechadores, módulos de la marca <span class="glifos-font">GLIFOS</span>, otros software e implementación de funcionalidades a la medida.</p>`,
        },
    ],
};