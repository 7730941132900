import React from "react";

import styles from "./DesktopCard.module.css";

const DesktopCard = (props) => {
  const defaultActive = props.id === 0 ? "show active" : "";
  return (
    <div
      className={`tab-pane fade ${defaultActive}`}
      id={`v-pills-${props.id}`}
      role="tabpanel"
      aria-labelledby={`v-pills-${props.id}-tab`}
    >
      <div className="d-xl-flex">
        <div className="col-12 col-lg-9 col-xl-4 pb-5">
          <div className={`mb-3 ${styles.headline}`} dangerouslySetInnerHTML={{ __html: props.header }}></div>
          <div dangerouslySetInnerHTML={{ __html: props.body }}></div>
        </div>
        <div className="col-12 col-lg-9 col-xl-7 text-center">
          <img
            className="img-fluid ps-3"
            style={{maxHeight: "572px"} }
            src={props.screenshot}
            alt={props.screenshot}
          />
        </div>
      </div>
    </div>
  );
};

export default DesktopCard;
