import React from "react";
import ReleaseHeader from "../../../components/ReleaseHeader/ReleaseHeader";
import ReleasePlan from "../../../components/ReleasePlan/ReleasePlan";

import { release_header, release_plan } from "../../../data/library_release10";

const R10 = () => {
  return (
    <main>
      <ReleaseHeader title={release_header.title} description={release_header.description} />
      <ReleasePlan header={release_plan.header} releases={release_plan.releases} />
    </main>
  );
};

export default R10;
