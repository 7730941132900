import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ContactUs from "./components/ContactUs/ContactUs";

import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Dummy from "./pages/Library/Dummy";
import Library from "./pages/Library/Library";
import R10 from "./pages/Library/Releases/R10";
import Licenses from "./pages/Licenses/Licenses";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";

const App = () => {
  useEffect(() => {
    ReactGA.initialize("G-4B5DTDH43M");

    //ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.send({hitType: "pageview", page: window.location.pathname + window.location.search})
  }, []);

  return (
    <Router>
      <Header />
      <div className="d-flex flex-column h-100">
        <Switch>
          <Route exact path="/library">
            <Library />
          </Route>
          <Route exact path="/es">
            <Dummy />
          </Route>
          <Route exact path="/library/releases/10">
            <R10 />
          </Route>
          <Route exact path="/licenses">
            <Licenses lang="en"/>
          </Route>
          <Route exact path="/licencias">
            <Licenses lang="es"/>
          </Route>
          <Route exact path="/privacypolicy">
            <PrivacyPolicy />
          </Route>
          <Route exact path="/">
            <Redirect to="/library" />
          </Route>
        </Switch>
        <ContactUs />
        <Footer />
      </div>
    </Router>
  );
};

export default App;
