import React from "react";

import './NavButton.css';
import styles from './NavButton.module.css';

const NavButton = (props) => {
  const defaultActive = props.id === 0 ? "active" : "";

  return (
    <button
      className={`nav-link text-start glifos-module-name NavButton ${styles.nav_link} ${defaultActive}`}
      id={`v-pills-${props.id}-tab`}
      data-bs-toggle="pill"
      data-bs-target={`#v-pills-${props.id}`}
      type="button"
      role="tab"
      aria-controls={`v-pills-${props.id}`}
      aria-selected="true"
    >
      {props.text}
    </button>
  );
};

export default NavButton;
