export const thesis_intro = {
  title: "GLIFOS - thesis self deposit",
  backgroundClass: "bg-volcanes",
  description: `Nuestro más reciente módulo permite a los estudiantes entregar sus documentos de graduación de forma digital en línea, integrado al sistema de biblioteca <span class="glifos-font">GLIFOS – library</span>, de manera que la información bibliográfica básica del documento es generada por el módulo, en el estándar MARC 21. La ficha bibliográfica es sometida a una revisión y finalización por el personal de biblioteca antes de ser publicada en el catálogo.`,
  primaryButton: {
    text: "Solicite una demostración",
    link: "#contactus",
  },
};

export const thesis_features = {
  backgroundColor: "#fbf9f8",
  columnCount: 4,
  columns: [
    {
      title: `Automatización`,
      body: `<p>Automatiza la recepción de los documentos de graduación que deben ser ingresados al catálogo de la biblioteca, agilizando el proceso de graduación de los estudiantes.</p>`,
    },
    {
      title: `Recepción a distancia`,
      body: `<p>Los estudiantes pueden ingresar sus documentos digitales en línea, desde cualquier lugar en que encuentren, ahorrándose el tiempo para trasladarse físicamente y de espera.</p>`,
    },
    {
      title: `Integración`,
      body: `<p>Obtiene los datos del estudiante a través consultas al sistema de control académico de la institución. Con la información ingresada por el estudiante genera los registros bibliográficos de forma automática en <span class="glifos-font">GLIFOS – library</span>.</p>`,
    },
    {
      title: `Firma electrónica`,
      body: `<p>Genera constancias de la entrega de documentos para los estudiantes, aprovechando las características de la firma electrónica avanzada permite asegurar que esta no ha sido alterada por un tercero.</p>`,
    },
  ],
};