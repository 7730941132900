import React from "react";
import ReleaseCard from "../ReleaseCard/ReleaseCard";
import ReleaseSection from "../ReleaseSection/ReleaseSection";

const ReleasePlan = (props) => {
  return (
    <section className="container-fluid py-4 py-lg-5 px-lg-5 glifos-bg-light-gray">
      <div className="row px-2">
        <div
          className="col-12 col-lg-8 mb-lg-5 mx-auto"
          dangerouslySetInnerHTML={{ __html: props.header }}
        ></div>
      </div>
      <div className="row">
        <div className="col-lg-3 d-none d-lg-block text-end pe-5 pt-4">
          <div className="sticky-top d-flex flex-column">
            {props.releases.map((release, index) => (
              <a href={`#${release.id}`} key={index} className="text-link mb-2">
                {release.title}
              </a>
            ))}
          </div>
        </div>
        <div className="col-12 col-lg-9">
          {props.releases.map((release, index) => (
            <ReleaseCard key={index} id={release.id} title={release.title}>
              {release.features.map((feature, index) => (
                <ReleaseSection
                  key={index}
                  id={feature.id}
                  title={feature.title}
                  released={feature.released}
                  category={feature.category}
                  description={feature.description}
                  details={feature.details}
                />
              ))}
            </ReleaseCard>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ReleasePlan;
