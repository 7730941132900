import React from "react";

import {
  library_intro,
  library_features,
  library_modules,
} from "../../data/library";
import { news_section } from "../../data/news";
import { thesis_intro, thesis_features } from "../../data/thesis";
import { signer_headline, signer_features, signer_intro } from "../../data/signer";
import { release_banner } from "../../data/library_release10";

import BackgroundHero from "../../components/BackgroundHero/BackgroundHero";
import VerticalTabs from "../../components/VerticalTabs/VerticalTabs";
import SimpleHeadline from "../../components/SimpleHeadline/SimpleHeadline";
import ColumnSection from "../../components/ColumnSection/ColumnSection";
import HeadlineHero from "../../components/HeadlineHero/HeadlineHero";
import { ojs_features, ojs_intro } from "../../data/ojs";
import { cloud_features, cloud_intro } from "../../data/cloud";

const Library = () => {
  return (
    <main>
      <BackgroundHero
        sectionId="library"
        backgroundClass={library_intro.backgroundClass}
        title={library_intro.title}
        description={library_intro.description}
        primaryButton={library_intro.primaryButton}
        secondaryButton={library_intro.secondaryButton}
      />
      <HeadlineHero title={release_banner.title} description={release_banner.description} image={release_banner.image} button={release_banner.button} />
      <ColumnSection
        sectionId="news"
        columnCount={news_section.columnCount}
        columns={news_section.columns}
        classNames="text-white glifos-bg-dark-gray"
      />
      <SimpleHeadline
        title={signer_headline.title}
        description={signer_headline.description}
        button={signer_headline.button}
      />
      <ColumnSection
        sectionId="features"
        title={library_features.title}
        backgroundColor={library_features.backgroundColor}
        columnCount={library_features.columnCount}
        columns={library_features.columns}
      />
      <VerticalTabs
        sectionId="modules"
        sectionTitle="Módulos"
        items={library_modules}
      />
      <BackgroundHero
        sectionId="cloud"
        backgroundClass={cloud_intro.backgroundClass}
        title={cloud_intro.title}
        description={cloud_intro.description}
        primaryButton={cloud_intro.primaryButton}
      />
      <ColumnSection
        sectionId="about-cloud"
        backgroundColor={cloud_features.backgroundColor}
        columnCount={cloud_features.columnCount}
        columns={cloud_features.columns}
      />
      <BackgroundHero
        sectionId="thesis"
        backgroundClass={thesis_intro.backgroundClass}
        title={thesis_intro.title}
        description={thesis_intro.description}
        primaryButton={thesis_intro.primaryButton}
      />
      <ColumnSection
        sectionId="about-thesis"
        backgroundColor={thesis_features.backgroundColor}
        columnCount={thesis_features.columnCount}
        columns={thesis_features.columns}
      />
      <BackgroundHero
        sectionId="glifosforojs"
        backgroundClass={ojs_intro.backgroundClass}
        title={ojs_intro.title}
        description={ojs_intro.description}
        primaryButton={ojs_intro.primaryButton}
      />
      <ColumnSection
        sectionId="about-glifosforojs"
        backgroundColor={ojs_features.backgroundColor}
        columnCount={ojs_features.columnCount}
        columns={ojs_features.columns}
      />
      <BackgroundHero
        sectionId="glifos-signer"
        backgroundClass={signer_intro.backgroundClass}
        title={signer_intro.title}
        description={signer_intro.description}
        primaryButton={signer_intro.primaryButton}
      />
      <ColumnSection
        sectionId="about-signer"
        title={signer_features.title}
        description={signer_features.description}
        columnCount={signer_features.columnCount}
        columns={signer_features.columns}
      />
    </main>
  );
};

export default Library;
