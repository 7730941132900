import React from "react";

const ReleaseHeader = (props) => {
  return (
    <section className="container-fluid py-4 py-lg-5 px-4 px-lg-5 glifos-bg-dark-gray text-white">
      <div className="col-12 col-lg-6 mx-auto text-center">
        <h5 className="glifos-text-yellow fst-italic">Release notes</h5>
        <h1 className="glifos-font mb-5" dangerouslySetInnerHTML={{ __html: props.title }}></h1>
        <div dangerouslySetInnerHTML={{ __html: props.description }}></div>
      </div>
    </section>
  );
};

export default ReleaseHeader;
