import React from "react";

import { license_terms } from "../../data/licenses";

const Licenses = (props) => {
  var dateFormat = require("dateformat");
  var now = new Date();

  return (
    <main>
      <section className="container-fluid py-4 py-lg-5 px-4 px-lg-5 ">
        <div className="row mb-4">
          <div
            className="col"
            dangerouslySetInnerHTML={{
              __html: license_terms[props.lang]?.replace(
                "%{current_date}%",
                dateFormat(now, "yyyy")
              ),
            }}
          ></div>
        </div>
      </section>
    </main>
  );
};

export default Licenses;
