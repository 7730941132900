import React from "react";

const Footer = () => {
  var dateFormat = require("dateformat");
  var now = new Date();
  
  return (
    <footer
      className="footer mt-auto py-3"
      style={{ backgroundColor: "#000", color: "#fff", fontWeight: 600 }}
    >
      <div className="container text-center">
        <span>Copyright &copy; 2000 - {dateFormat(now, "yyyy")} Infolib, S.A.</span>
      </div>
    </footer>
  );
};

export default Footer;
