export const signer_headline = {
  title: "GLIFOS - signer",
  description: `Nuestro servicio para firma electrónica avanzada el cual permite la automatización de firma de documentos a través de un API.`,
  button: {
    text: "Conozca más",
    link: "#about-signer",
  },
};

export const signer_intro = {
  title: "GLIFOS - signer",
  backgroundClass: "bg-jupiter-bottom",
  description: `Nuestro servicio de firma electrónica avanzada, implementado en la nube, este servicio permite la firma de documentos de forma masiva y automatizada, añadiendo autenticidad y veracidad a los documentos emitidos por el servicio. Es totalmente compatible con cualquier aplicación ya que se integra a través de servicios web.`,
  primaryButton: {
      text: "Solicite información",
      link: "#contactus",
  },
};

export const signer_features = {
  columnCount: 3,
  columns: [
    {
      title: `Seguro y rápido`,
      body: `<p>Optimiza el tiempo de firmado de documentos e implementa políticas de seguridad para ayudarle a proteger su identidad como firmante.</p>`,
    },
    {
      title: `No más papel`,
      body: `<p>Ayuda a las instituciones a la reducción de papel impreso, reducción de tiempo por parte de los firmantes y es compatible con los sistemas que siguen las nuevas tendencias en documentación digital</p>`,
    },
    {
      title: `Adaptable`,
      body: `<p>Compatible con los certificados y llaves más utilizadas (extensiones pfx, crt, pem) y es independiente del proveedor de los mismos.</p>`,
    },
  ],
};
