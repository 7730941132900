export const library_intro = {
  title: "GLIFOS - library",
  backgroundClass: "bg-library",
  description: `<span class="glifos-font">GLIFOS - library</span> es nuestro software de bibliotecas que permite manejar materiales digitales en forma integrada con los materiales físicos.  Aprovechamos al máximo el potencial de Internet, servicios en la nube, Inteligencia Artificial y otras tecnologías para prestarle un mejor servicio a los usuarios de las bibliotecas.`,
  primaryButton: {
    text: "Solicite una demostración",
    link: "#contactus",
  },
  secondaryButton: {
    text: "Características",
    link: "#features",
  },
};

export const library_features = {
  title: "Características",
  backgroundColor: "#fbf9f8",
  columnCount: 3,
  columns: [
    {
      title: `Alojamiento en la nube e inteligencia artificial`,
      body: `<p>Mediante la implementación de servicios en la nube, <span class="glifos-font">GLIFOS - library</span> puede almacenar y acceder a grandes volúmenes de información de manera segura y escalable, sin requerir una infraestructura local costosa y limita. Esto permite a las bibliotecas ampliar su capacidad de almacenamiento y ofrecer servicios más rápidos y eficientes a sus usuarios.</p>
      <p>Los algoritmos de AI permiten a las bibliotecas agilizar procesos y mejorar la experiencia del usuario al permitirle acceder a la información de manera rápida y precisa.</p>
      <p>Se utiliza en el reconocimiento óptico de caracteres (OCR) facilitando su búsqueda (<i>fulltext</i>). También permite atender solicitudes o consultas comunes de los usuarios a través de un <i>chatbot</i>, actúa como primera línea de atención al usuario, aprovecha el reconocimiento del lenguaje natural para brindar una experiencia fluida y natural durante la conversación.</p>`,
    },
    {
      title: `Bajo costo de ownership`,
      body: `<p>El costo de ownership representa todos aquellos gastos en los que tiene que incurrir la empresa o institución para mantener un sistema computarizado en funcionamiento; por lo que incluye, además del costo del producto, otros costos como las licencias de las herramientas empleadas y sus planes de mantenimiento, hardware, personal a cargo de administrar el sistema, etc.</p><p>Se tomaron en cuenta tres factores para reducir los costos de ownership:</p><ul><li>Costo cero en el software de cada estación de trabajo.</li><li>Administración sencilla de las estaciones cliente.</li><li>Una sola plataforma de implementación.</li></ul>`,
    },
    {
      title: `Servicios a los usuarios de la biblioteca`,
      body: `<p>Al estar basada en una aplicación web, <span class="glifos-font">GLIFOS - library</span> se beneficia en varios aspectos, que van desde la administración del sistema, hasta la funcionalidad que ofrece. A nivel de funcionalidad, permite:</p><ul><li>Búsqueda y navegación de la información bibliográfica.</li><li>Catalogación y búsqueda de documentos digitales. </li><li>Disponibilidad y reserva de materiales.</li><li>Estatus de usuario</li><li>Notificaciones vía email.</li><li>Estadísticas de uso.</li><li>Compra en línea.</li></ul>`,
    },
  ],
};

export const library_modules = [
  {
    title: "Consultas (OPAC)",
    header: `Consulte el catálogo de la biblioteca desde cualquier dispositivo.<span class="ms-2 badge bg-success bg-gradient"><i class="fas fa-certificate"></i> NEW!</span>`,
    body: `<p>Nuestro catálogo en línea ha sido renovado para darle una cara simple y moderna a las bibliotecas, utilizando las tecnologías más recientes para diseño web.</p>
    <ul>
    <li class="mt-2">Permite la consulta desde cualquier dispositivo ya que utiliza un diseño <i>web responsive</i>.</li>
    <li class="mt-2">Su portada es personalizable, permitiendo construir una <i>landing page</i> para las bibliotecas o desplegar información según sus necesidades.</li>
    <li class="mt-2">Permite realizar búsquedas simples, con parámetros sencillos, facilitando su uso a los usuarios.</li>
    <li class="mt-2">Permite realizar búsquedas avanzadas, para los usuarios que desean resultados específicos.</li>
    <li class="mt-2">Despliega portadas de los materiales.</li>
    <li class="mt-2">Despliega el estado de las copias de cada material en tiempo real.</li>
    <li class="mt-2">Permite reservar materiales de forma remota.</li>
    <li class="mt-2">Permite acceso a materiales digitales.</li>
    </ul>`,
    screenshot: "/assets/library/10.0/screenshots/opac_record.png",
  },
  {
    title: "Catalogación e inventario",
    header: "Reduce el esfuerzo y tiempo de catalogación e inventario.",
    body: `<ul><li class="mt-2">Catalogación bibliográfica usando el formato MARC 21.</li><li class="mt-2">Control de catalogación bibliográfica e inventario de materiales en una sola interfaz.</li><li class="mt-2">Ajustable a cualquier estándar de registro bibliográfico.</li><li class="mt-2">Evita la duplicidad de registros.</li><li class="mt-2">Control y actualización automática de autoridades.</li><li class="mt-2">Facilita el ingreso y validación de la información.</li><li class="mt-2">Permite crear nuevas citas bibliográficas a partir de citas existentes.</li><li class="mt-2">Acepta un número limitado de autores, palabras clave, encabezamientos de materia y copias de inventario.</li></ul>`,
    screenshot: "/assets/library/8.2/screenshots/cataloging_edit.png",
  },
  {
    title: "Capture X",
    header: "Automatiza la catalogación de materiales, agilizando el trabajo del bibliotecario.",
    body: `<ul><li class="mt-2">Captura de registros de otras bibliotecas sin costo.</li><li class="mt-2">Acepta múltiples formatos estándar de catalogación.</li><li class="mt-2">Traduce automáticamente un gran número de campos que utilizan un lenguaje controlado.</li><li class="mt-2">Permite configurar diccionarios de traducción para cada institución.</li><li class="mt-2">Es posible configurar catálogos de biblioteca adicionales</li></ul>`,
    screenshot: "/assets/library/8.2/screenshots/capturex_capture.png",
  },
  {
    title: "Circulación",
    header: `Permite realizar transacciones de circulación en una sola pantalla de forma integrada.<span class="ms-2 badge bg-success bg-gradient"><i class="fas fa-certificate"></i> NEW!</span>`,
    body: `<p>Nuestro módulo de circulación ha sido renovado con una interfaz simple y moderna que facilita su uso para los operadores.</p>
    <ul>
    <li class="mt-2">Permite utilizar el código de barras para controlar el préstamo y devolución de materiales.</li>
    <li class="mt-2">Configuración para trabajar en bibliotecas de estantería abierta o cerrada al público.</li>
    <li class="mt-2">Permite verificar y actualizar datos personales del usuario.</li>
    <li class="mt-2">Permite configurar de forma granular la capacidad de préstamo y reserva para cada usuario o material.</li>
    <li class="mt-2">El staff de circulación puede agregar anotaciones o memos como parte de los datos de un usuario.</li><li class="mt-2">Permite enviar notificaciones automáticas vía email.</li>
    <li class="mt-2">Permite el cálculo automático de multas por atraso y registro detallado de multas a pagar.</li>
    <li class="mt-2">Permite integrarse con sistemas de control académico de la institución.</li>
    </ul>`,
    screenshot: "/assets/library/10.1/screenshots/circulation_station.png",
  },
  {
    title: "Reportes",
    header: "Permite generar reportes y cuadros estadísticos de forma impresa o en páginas web.",
    body: `<ul><li class="mt-2">Permite a los usuarios diseñar y añadir nuevos reportes.</li><li class="mt-2">Permite incluir links a otras páginas en los reportes.</li><li class="mt-2">Permite verificar y actualizar datos personales del usuario.</li><li class="mt-2">Entre los reportes incluidos están: boletín de nuevas adquisiciones, estadísticas de catalogación por mes, impresión de lomos, etc.</li><li class="mt-2">Entre las consultas en línea están: cantidad de títulos desglosado por tipo de material y por idioma, cantidad de materiales en la colección.</li></ul>`,
    screenshot: "/assets/library/8.2/screenshots/reports_generated.png",
  },
  {
    title: "Administración",
    header: `Permite administrar el sistema a través de una sola interfaz controlada. <span class="ms-2 badge bg-success bg-gradient"><i class="fas fa-certificate"></i> NEW!</span>`,
    body: `<p>Nuestro módulo de administración se ha renovado, con una interfaz simple y moderna. Permite a los bibliotecarios gestionar las configuraciones y usuarios del sistema.</p>
    <ul>
    <li class="mt-2">Permite gestionar usuarios para el staff de biblioteca.</li>
    <li class="mt-2">Permite gestionar cuentas para usuarios que realizan prestamos en la biblioteca (usuarios externos).</li>
    <li class="mt-2">Permite la configuración de reglas de circulación: tiempo de préstamo, tiempo de reserva, límite de reservas, monto por mora, etc.</li>
    <li class="mt-2">Actualización global de registros maestros (instituciones, ubicaciones, departamentos, etc.).</li>
    <li class="mt-2">Permite asignar roles granulares al staff de biblioteca, mostrando solo las operaciones y módulos disponibles según los permisos asignados.</li>
    <li class="mt-2">Integración a autenticación SSO</li>
    </ul>`,
    screenshot: "/assets/library/10.1/screenshots/admin_main_menu.png",
  },
  {
    title: "Asistente virtual (Chatbot)",
    header: `Atiende consultas de los usuarios de forma automática, actuando como primera línea de atención a los usuarios. <span class="ms-2 badge bg-success bg-gradient"><i class="fas fa-certificate"></i> NEW!</span>`,
    body: `<ul>
    <li class="mt-2">Utilizando el reconocimiento de lenguaje natural, ofrece una experiencia fluida y natural a los usuarios de la biblioteca.</li>
    <li class="mt-2">Plugin de chat integrado con Facebook Messenger, compatible con cualquier página web, incluyendo nuestro nuevo OPAC.</li>
    <li class="mt-2">Atiende solicitudes comúnmente realizadas por los usuarios, permite trasladar la conversación al staff de biblioteca si no es capaz de atender una solicitud.</li>
    <li class="mt-2">Permite el acceso al historial de mensajes enviados y recibidos, no importando si fueron atendidos por el chatbot o por el staff de biblioteca.</li>
    </ul>`,
    screenshot: "/assets/library/10.0/screenshots/chatbot_chat.png",
  },
  {
    title: "Thesis self deposit",
    header: `Permite a los estudiantes entregar sus documentos de graduación de forma digital en línea, desde cualquier lugar en que se encuentren. <span class="ms-2 badge bg-success bg-gradient"><i class="fas fa-certificate"></i> NEW!</span>`,
    body: `
    <p>Nuestro módulo de auto depósito de tesis ha sido renovado con una interfaz simple, moderna y con nuevas funcionalidades que agilizan el proceso de entrega y revisión de documentos.</p>
    <ul>
    <li class="mt-2">Permite a la institución configurar una lista de cotejo con la cual deben cumplir los estudiantes en su proceso de entrega presentado evidencias en el documento consignando.</li>
    <li class="mt-2">Visor PDF que permite al usuario marcar las evidencias de cumplimiento con cada uno de los requerimientos de la lista de cotejo.</li>
    <li class="mt-2">Proceso seccionado en pasos para entrega y revisión, permitiendo a los usuarios enfocarse en micro actividades reduciendo la posibilidad de consignación de datos erróneos.</li>
    <li class="mt-2">Flexibilidad de integración con sistemas de control académico para consulta de datos del estudiante o SSO para autenticación con cuentas institucionales.</li>
    <li class="mt-2">Notificaciones por correo electrónico por cada operación y cambio de estado de la solicitud.</li>
    <li class="mt-2">Generación de constancias de entrega con posibilidad de integración para firma electrónica avanzada.</li>
    </ul>`,
    screenshot: "/assets/library/10.1/screenshots/thesis_user_wizard_2.png",
  },
  {
    title: "Procesos técnicos",
    header: `Permite realizar traslados de materiales entre áreas para procesos internos.<span class="ms-2 badge bg-success bg-gradient"><i class="fas fa-certificate"></i> NEW!</span>`,
    body: `<ul>
    <li class="mt-2">Permite la configuración de áreas, staff perteneciente a cada una y el estado al que cambia el material al ser trasladado a cada una.</li>
    <li class="mt-2">Permite configurar tipos de transacción realizadas en cada solicitud de movimiento.</li>
    <li class="mt-2">Permite realizar traslados de materias a un área ya sea de forma individual o por lote.</li>
    <li class="mt-2">Notificaciones por correo para cada transacción a todos los involucrados en la operación.</li>
    <li class="mt-2">Incluye reportes para la visualización de estado del actual de cada material, historial, movimientos por área y materiales disponibles en cada área.</li>
    </ul>`,
    screenshot: "/assets/library/10.1/screenshots/technicalprocess_station.png",
  },
];
