import React from "react";

const HeadlineHero = (props) => {
  return (
    <section class="container-fluid py-2 px-4 px-lg-5 bg-white">
      <div class="row flex-lg-row-reverse align-items-center g-5 py-3">
        <div class="col-10 col-sm-8 col-lg-6 d-none d-lg-block">
          <a href={props.image.href}>
            <img
              src={props.image.src}
              class="d-block mx-lg-auto img-fluid"
              alt="headline_img"
              width="500"
              height="300"
              loading="lazy"
            />
          </a>
        </div>
        <div class="col-lg-6">
          <h3
            class="lh-1 mb-3"
            dangerouslySetInnerHTML={{ __html: props.title }}
          ></h3>
          <p
            class="lead"
            dangerouslySetInnerHTML={{ __html: props.description }}
          ></p>
          <div class="d-grid gap-2 d-md-flex justify-content-md-start">
            <a
              href={props.button.href}
              class="btn btn-secondary btn-lg px-4 me-md-2"
            >
              {props.button.title}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeadlineHero;
