import React from "react";

const BackgroundHero = (props) => {
  return (
    <section
      id={props.sectionId}
      className={`container-fluid py-4 py-lg-5 px-4 px-lg-5 ${props.backgroundClass}`}
    >
      <div className="row flex-lg-row align-items-center overflow-hidden text-white">
        <div className="col-12 col-xl-7 col-xxl-5 offset-xl-1 p-lg-5">
          <h1 className="lh-1 mb-3 glifos-main-headline" dangerouslySetInnerHTML={{ __html: props.title }} />
          <p
            className="lead"
            dangerouslySetInnerHTML={{ __html: props.description }}
          ></p>
          <div className="d-block d-lg-flex flex-row">
            <div className="mb-3 mb-lg-0 me-lg-3">
              <a
                href={props.primaryButton.link}
                className="btn btn-light btn-lg px-4"
              >
                {props.primaryButton.text}
              </a>
            </div>
            {props.secondaryButton && (
              <div>
                <a
                  href={props.secondaryButton.link}
                  className="btn btn-outline-light btn-lg px-4"
                >
                  {props.secondaryButton.text}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BackgroundHero;
