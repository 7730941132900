import React from "react";
import styles from "./Header.module.css";

const Header = () => {
  return (
    <header>
      <nav
        className={`navbar navbar-expand-lg navbar-dark shadow ${styles.navbar}`}
      >
        <div className="container-fluid">
          <a className="navbar-brand" href="/" style={{padding: '0', marginBottom: '7px'}}>
            <img
              src="/assets/logos/GLIFOS-moon.png"
              alt="GLIFOS logo"
              width="150"
              height="34"
              className="d-inline-block align-middle"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
            <ul className="d-flex navbar-nav">
              <li className="nav-item">
                <a
                  className="nav-link active border rounded"
                  aria-current="page"
                  href="#contactus"
                >
                  <div className="d-flex flex-row">
                  <div className="d-block ps-2" style={{ Color: "#fff" }}>
                    <i className="fas fa-phone-alt"></i>
                  </div>
                  <div className="px-2">
                  Contáctenos
                  </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
