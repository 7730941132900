export const news_section = {
  columnCount: 4,
  columns: [
    
    {
      title: `<span class="glifos-font">GLIFOS - support</span>`,
      body: `<p class="mb-2">Ponemos a la disposición de todos nuestros clientes, la nueva plataforma de tickets para atender solicitudes de soporte. Ingresa y regístrate para recibir apoyo de nuestro equipo de soporte.</p>`,
      actionBtn: true,
      actionBtnText: `Solicite soporte`,
      actionBtnHref: "https://support.glifos.com/",
      actionBtnTarget: "_blank"
    },
    {
      title: `<span class="glifos-font">GLIFOS - cloud</span>`,
      body: `<p class="mb-2">Una nueva forma de tener acceso a nuestros sistemas con costos reducidos, facilitando el proceso de adquisición de servicios sin preocuparse del mantenimiento de la infraestructura.</p>`,
      actionBtn: true,
      actionBtnText: `Ver más detalles`,
      actionBtnHref: "#cloud",
    },
    {
      title: `<span class="glifos-font">GLIFOS for <i>Open Journal Systems</i> (OJS)</span>`,
      body: `<p class="mb-2">¿Necesita servicios de instalación, configuración o actualización para OJS? Nuestra <i>suite</i> de servicios <span class="glifos-font">GLIFOS for <i>Open Journal Systems</i></span> es lo que necesita.</p>`,
      actionBtn: true,
      actionBtnText: `Conozca más`,
      actionBtnHref: "#glifos-signer",
    },
    {
      title: `<span class="glifos-font">GLIFOS - thesis self deposit</span>`,
      body: `<p class="mb-2">Conozca nuestro módulo para el auto depósito de tesis, integrado totalmente con el sistema de biblioteca, permite al estudiante entregar su trabajo de graduación automatizando y agilizando los procesos de graduación.</p>`,
      actionBtn: true,
      actionBtnText: `Ver más detalles`,
      actionBtnBg: "#fecc0c",
      actionBtnHref: "#thesis",
    },
    // {
    //   title: `<span class="glifos-font">GLIFOS - learning</span>`,
    //   body: `<p>Anunciando nuestra plataforma de aprendizaje en línea, aprende a utilizar los módulos de nuestros sistemas desde la comodidad de tu hogar. El acceso a la plataforma es gratuito para todos nuestros clientes dentro del plan de soporte anual, ingresa al sitio y regístrate ahora. </p><p><a href="https://learning.glifos.net/" target="_blank" rel="noreferrer" class="text-link-white">Visite la plataforma</a></p>`,
    // },
  ],
};
