import React from "react";

const ReleaseCard = (props) => {
  return (
    <div className="mb-3" id={props.id}>
      <div className="bg-white rounded shadow-sm">
        <div className="glifos-bg-yellow rounded-top p-4 glifos-title">
          {props.title}
        </div>
        <div className="p-4">{props.children}</div>
      </div>
    </div>
  );
};

export default ReleaseCard;
