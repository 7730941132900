import React from "react";

const Column = (props) => {
  return (
    <div className="col mb-3 mb-xxl-0">
      <h5 className="glifos-subheadline" dangerouslySetInnerHTML={{ __html: props.title }}></h5>
      <div dangerouslySetInnerHTML={{ __html: props.body }}></div>
      {props.actionBtn === true && (
        <a className="btn btn-outline-warning btn-sm" href={props.actionBtnHref} target={props.actionBtnTarget} rel="noreferrer">{props.actionBtnText}</a>
      )}
    </div>
  );
};

export default Column;
